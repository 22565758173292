<template>
  <b-modal size="sm" :id="'modal-add-payment' + detail.id" :title="detail.code + ' | ' + detail.benefit" ok-only hide-footer no-close-on-backdrop>
    <div class="isCentered isSpinner" v-if="isSaving">
        <center> 
          <b-spinner class="isCentered" label="Spinning"></b-spinner> <br>
            Acción en proceso
        </center>
      </div> 
    <validation-observer ref="addConsumption" tag="form" v-slot="{ invalid }">	    
      <b-form @submit.prevent="saveConsumption(consumption)"> 
        <b-row class="mb-2">
          <b-col>
            <ValidationProvider rules="required" name="cantidad">
              <b-form-group label="Cantidad" slot-scope="{ valid, errors }">
                <b-form-input
                  class="form-control"
                  type="number"
                  step="any"
                  placeholder="Agrege cantidad"
                  v-model="consumption.quantity"                  
                  :state="errors[0] ? false : valid ? true : null"
                >
                </b-form-input>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="12">
            <ValidationProvider rules="required" name="Fecha">
              <b-form-group label="Fecha de Operación" slot-scope="{ valid, errors }">
                <b-form-input                            
                  type="date"
                  :min="initialDate"
                  :max="detail.enddate"
                  :state="errors[0] ? false : valid ? true : null"
                  v-model="consumption.date"
                />
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="12">
            <ValidationProvider rules="required" name="Notas">
              <b-form-group label="Notas del abono">
                <b-form-textarea
                  v-model="consumption.note"
                  placeholder="Escribe la nota"
                  :maxlength="100"
                  rows="2"
                >
                </b-form-textarea>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="12">
            <div class="text-right">
              <b-button :disabled="invalid || !validbalance || isSaving" variant="primary" type="submit">Guardar</b-button>              
            </div>
          </b-col>

        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"
import * as moment from 'moment'
import { utils } from '@/modules/fivesClub/mixins/utils'
import { heightFade } from '@core/directives/animations'
import { showAlertMessage, currentDate } from '@/helpers/helpers' 

export default {
  mixins: [utils], 
  directives: {
    heightFade
  },
  props: {
    detail: {
      type: Object,
      required: true,
    },
    idContract: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isSaving: false,
      consumption:{
        quantity: 0,
        note: '',
        date: '',
      },
      datenow: new Date(),
      validbalance: true
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    initialDate(){
      const currentDate = moment().startOf('day')
			const beforeYear=moment(currentDate).add(-6, "months").format("YYYY-MM-DD")
      const currentDateIsAfterTheInitialDate = beforeYear
      return currentDateIsAfterTheInitialDate 
    },
  },
  methods: {
    ...mapActions('fivesClubContracts', ['saveDetailTransactions', 'fetchTransactionsDetail','fetchTransactions']),
    ...mapMutations('fivesClubContracts', ['setTransactions','setDetailTransactions']),
    async saveConsumption(data) {
      this.isSaving = true
      const { quantity, note, date } = data
      const payload = { id: this.detail.id, quantity: parseFloat(quantity), idUser: this.user.idUser, note, date, type: 2 }
      const { status, message } = await this.saveDetailTransactions(payload)
      if(status){
        showAlertMessage('Proceso terminado', 'InfoIcon', `Se ha guardado el registro: ${message}` , 'success', 4000, 'bottom-right' )
      } else {
        showAlertMessage('Error', 'InfoIcon', `Proceso terminado: ${message || ''}` , 'danger', 4000, 'bottom-right' )
      }
      const info = {
        id: this.idContract,
        year: this.datenow.getFullYear(),
      };
      const infoDetail = {
        id: this.detail.id,
      };
      this.$emit('is-loading-transactions', true)
      const transactions = await this.fetchTransactions(info)
      this.setTransactions(transactions)
      this.$emit('is-loading-transactions', true)
      const detailTransactions = await this.fetchTransactionsDetail(infoDetail)
      this.setDetailTransactions(detailTransactions)

      this.consumption.quantity = 0
      this.consumption.note = ''
      this.consumption.date = ''      
      this.$root.$emit("bv::hide::modal", "modal-add-payment" + this.detail.id)
      this.$emit('is-loading-transactions', false)
      this.isSaving = false
    },    
  },
};
</script>