<template>
  <b-row>    
    <b-col class="col-md-12" v-if="transactions.length > 0">
      <b-table
        hover
        show-empty
        responsive
        small
        sticky-header="400px"
        empty-text="No hay transacciones para mostrar"
        :items="transactions"
        :fields="fields"
        :tbody-tr-class="rowClass"
        @row-clicked="setDetail"
        class="mb-0"
      >            
        <template #cell(actions)="row">
          <b-dropdown toggle-class="p-0" no-caret right v-if="(row.item.statusContract != 3)" >
            <template #button-content>
              <b-button variant="primary" size="sm">
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle" style="color: white" />
              </b-button>
            </template>
            <b-dropdown-item @click="openModalAddConsumption(row.item)" v-can="'fivesclub_contracts_show_contracts_action_button_transactions_add_button'">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50"> Añadir consumo</span>
            </b-dropdown-item>
            <b-dropdown-item @click="openModalAddPayment(row.item)" v-can="'fivesclub_contracts_show_contracts_action_button_transactions_action_add_credit'">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50"> Añadir abono</span>
            </b-dropdown-item>
            <b-dropdown-item @click="openModalDeleteTransaction(row.item)" v-if=" can('fivesclub_contracts_show_contracts_action_button_transactions_delete_button') && row.item.isExtra">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50"> Borrar</span>
            </b-dropdown-item>
          </b-dropdown>

          <!-- Modal para añadir consumo -->
          <ModalAddConsumption
            :detail="row.item"
            :idContract="idContract"
            @is-loading-transactions="setLoadingTransactions"
          />
          <!-- Modal para añadir abono -->
          <ModalAddPayment
            :detail="row.item"
            :idContract="idContract"
            @is-loading-transactions="setLoadingTransactions"
          />
          <ModalDeleteTransaction
            :detail="row.item"
            @delete-transaction="deleteTransaction"
          />
        </template>

        <template #cell(canceldate)="data">
          <span v-if="data.item.canceldate">
            {{ formatDateInfo(data.item.canceldate, "es") }}
          </span>
        </template>
      </b-table>

      <hr />

      <div v-if="detailTransactions.length > 0">
        <h5>Detalle de transacciones</h5>

        <b-table
          responsive
          small
          sticky-header="400px"
          :items="detailTransactions"
          :fields="fieldsDetail"
          :tbody-tr-class="rowClassCancel"
          class="mb-2"
        >
          <!-- Optional default data cell scoped slot -->
          <template #cell(createdateFormart)="data">
            {{ formatDateInfo(data.item.createdate, "es") }}
          </template>
          <template #cell(actions)="row">
            <b-button
              size="sm"
              variant="light"
              v-if="showBtnDeleteTD(row.item) && showBtnByPermisson"
              @click="openModalCancelDetailTransaction(row.item)"
              class="mr-2"
            >
              <!-- v-can="'fivesclub_contracts_show_contracts_action_button_transactions_delete'" -->
              <feather-icon icon="TrashIcon" size="16" />
            </b-button>
            <ModalCancelTransactionDetail
              :detail="row.item"
              @cancel-detail-transaction="cancelDetailTransaction"
            />
          </template>
        </b-table>
      </div>

      <div v-if="detailTransactions.length == 0 && setDetailClass != ''">
        <b-alert show variant="danger">
          <div class="alert-body text-center">
            <span>Esta contrato no tiene detalle transacciones realizadas</span>
          </div>
        </b-alert>
      </div>
    </b-col>

    <b-col v-else>
      <b-alert show variant="danger">
        <div class="alert-body text-center">
          <span>Esta contrato no tiene transacciones realizadas</span>
        </div>
      </b-alert>
    </b-col>

  </b-row>
</template>

<script>
import ModalAddConsumption from '@/modules/fivesClub/components/contracts/ModalAddConsumption';
import ModalAddPayment from '@/modules/fivesClub/components/contracts/ModalAddPayment';

import ModalDeleteTransaction from '@/modules/fivesClub/components/contracts/ModalDeleteTransaction'
import ModalCancelTransactionDetail from '@/modules/fivesClub/components/contracts/ModalCancelTransactionDetail'

import { formatDateOnly } from '@/helpers/helpers';
import { mapActions, mapState, mapMutations } from 'vuex';
import { showAlertMessage } from '@/helpers/helpers'
import { acl } from "@/modules/auth/mixins/acl"

export default {
  mixins: [acl], 
   
  props: {
    idContract: {
      type: Number,
      required: true,
    },
    isLoadingTransactions:{
      type: Boolean,
    },
  },
  components: {
    ModalAddConsumption,
    ModalAddPayment,
    ModalCancelTransactionDetail,
    ModalDeleteTransaction,
  },
  data() {
    return {
      quantity: "",
      setDetailClass: "",
      note: "",
      fields: [
        { key: "code", label: "Código", class: "text-center" },
        { key: "benefit", label: "Beneficio", class: "text-center" },
        { key: "quantity", label: "Cantidad" },
        { key: "balance", label: "Disponible" },
        { key: "used", label: "Usado" },
        { key: "currency", label: "Moneda" },
        { key: "initialdate", label: "Fecha inicio" },
        { key: "enddate", label: "Fecha final" },
        { key: "notes", label: "Notas" },
        { key: "isExtra", label: "Beneficio Extra", class:"text-center", formatter: value => {
          return value ? 'Si' : 'No'
        } },
        { key: "isTransfer", label: "Traspaso de Saldo", class:"text-center", formatter: value => {
          return value ? 'Si' : 'No'
        } }, 
        // { key: "canceldate", label: "Fecha de Cancelación" },
        // { key: "internalNotes", label: "Notas de Cancelación" },

        { key: "statusTransactions", label: "Estatus" },
        { key: "userName", label: "Usuario" },
        { key: "actions", label: "Acciones" },
      ],
      fieldsDetail: [
        { key: "benefit", label: "Beneficio", class: "text-center" },
        { key: "invoice", label: "Orden" },
        { key: "quantity", label: "Cantidad" },
        { key: "userName", label: "Creado por" },
        { key: "statusTransactions", label: "estatus" },
        { key: "createdateFormart", label: "Fecha de creacion" },
        { key: "operationdate", label: "Fecha de operación" },
        { key: "notes", label: "Notas" },       
        { key: "userCancelName", label: "Cancelado por" },
        { key: "cancelNotes", label: "Notas de Cancelación" },
        { key: "typeTransactions", label: "Movimiento" },

        { key: "actions", label: "Acciones" },
      ],
      datenow: new Date(),
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState('fivesClubContracts', ['transactions','detailTransactions']),
    showBtnByPermisson(){      
      return this.can('fivesclub_contracts_show_contracts_action_button_transactions_action_delete_credit')
    }
  },
  methods: {
    ...mapActions("fivesClubContracts", ["fetchTransactionsDetail", "fetchTransactions", "deleteDetailTransactions", "deleteTransactions", ]),
    ...mapMutations('fivesClubContracts', ['setTransactions','setDetailTransactions']),
    formatDateInfo(fecha, locale) {
      return formatDateOnly(fecha, locale);
    },
    // show btn transaction detail
    showBtnDeleteTD(transaction){
      const { statusTransactions, orderdetail, isCredit, typeTransactions, statusContract, ownerbooking } = transaction
      const CANCELADO = 'Cancelado'
      const ABONO = 'Abono'
      const INICIAL = 'Inicial'
      const CIERRE = 'Cierre'
      let showBtn = false
      if ( statusContract == 3 ) showBtn = false
      if ( ownerbooking ) showBtn = false
      if( statusTransactions === CANCELADO ) showBtn = false
      if( typeTransactions === INICIAL || typeTransactions === CIERRE  ) showBtn = false
      if( statusTransactions !== CANCELADO ){
        if( typeTransactions === ABONO ) showBtn = isCredit ? isCredit : false
        if( typeTransactions !== ABONO ) showBtn = statusTransactions !== CANCELADO && !orderdetail
      }
      return showBtn
    },
    
    rowClass(item, type) {
      if (!item || type !== "row") return
      if (item.id === this.setDetailClass) return "table-success"
      if (item.statusTransactions === 'Cancelado') return "table-danger"
    },
    rowClassCancel(item, type) {
      if (!item || type !== "row") return;
      if (item.statusTransactions === "Cancelado") return "table-danger";
    },
    async setDetail(record, index, e) {
      this.$emit('set-is-loading-transactions-detail', true)
      this.setDetailTransactions([])
      this.setDetailClass = ""
      const infoDetail = { id: record.id, }
      const detailTransactions = await this.fetchTransactionsDetail(infoDetail)
      this.setDetailTransactions(detailTransactions)
      this.setDetailClass = record.id
      this.$emit('set-is-loading-transactions-detail', false)
    },
    async deleteTransaction(data) {
      this.$emit('set-is-loading-transactions', true)
      this.setDetailClass = ""
      const payload = { idUser: this.user.idUser, id: this.idContract, notes: data.internalNotes, transactionId: data.id, benefitID: data.benefitId, }
      const info = { id: this.idContract, year: this.datenow.getFullYear() }
      const response = await this.deleteTransactions(payload)
      if (response.status) {
        const transactions = await this.fetchTransactions(info)
        this.setTransactions(transactions)
        this.setDetailTransactions([])
      } else if (!response.status){
        showAlertMessage('No se puede borrar la transacción', 'BellIcon', response.message, 'danger', 3000, 'bottom-right')
      }
      this.$emit('set-is-loading-transactions', false)
    },
     async cancelDetailTransaction(data) {
      this.$emit('set-is-loading-transactions-detail', true)
      this.setDetailTransactions([])
      const payload = {
        idUser: this.user.idUser,
        quantity: parseFloat(data.quantity != "" ? data.quantity : 0),
        id: data.id,
        transaction: data.transaction,
        note: data.notesInfo,
      }

      await this.deleteDetailTransactions(payload)

      const info = { id: this.idContract, year: this.datenow.getFullYear() }
      const transactions = await this.fetchTransactions(info)
      this.setTransactions(transactions)
      const detailTransactions = await this.fetchTransactionsDetail({id: data.transaction})
      this.setDetailTransactions(detailTransactions)
      this.$emit('set-is-loading-transactions-detail', false)
    },

    openModalAddConsumption(data) { //consumo
      this.$root.$emit("bv::show::modal", "modal-add-consumption" + data.id)
    },
    openModalAddPayment(data) { //abono
      this.$root.$emit("bv::show::modal", "modal-add-payment" + data.id)
    },
    setLoadingTransactions(bool){
      this.$emit('set-is-loading-transactions', bool)
    },
    openModalDeleteTransaction(data) {
      this.$root.$emit("bv::show::modal", "modal-transaction-delete" + data.id )
    },
    openModalCancelDetailTransaction(data) {
      this.$root.$emit("bv::show::modal", "modal-cancel-detail-transaction" + data.id)
    },
  },
};
</script>


<style lang="scss" >
@media (max-width: 1280px) {
  .mb-0.table-responsive {
    max-height: 369px;
  }
  .table-responsive th {
    padding: 10px 10px 10px 10px !important;
    font-size: 10px;
  }
  .table-responsive th div {
    font-size: 10px;
  }
  .table-responsive td {
    padding: 5px !important;
    font-size: 9px;
  }
  .buttonInfo button {
    padding: 9px;
  }

  .buttonInfo {
    display: table-cell;
  }
}
</style>
