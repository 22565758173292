<template>
  <div>
    <b-card>
      <div class="float-right">
        <b-button
          class="btn mr-1"
          type="submit"
          size="sm"
          variant="primary"
          v-b-tooltip.hover
          title="Descargue aquí el Saldo de Beneficios"
          :href="`${authURL}/FivesClub/benefitbalance/${idContract}/${year}/`"
          target="_blank"
          rel="noopener noreferrer"
        ><feather-icon icon="FileTextIcon" /> Saldo de Beneficios
        </b-button>

        <b-dropdown variant="link" toggle-class="p-0" no-caret right>
          <template #button-content>
            <feather-icon icon="MoreVerticalIcon" size="24" class="align-middle text-body" />
          </template>
          <b-dropdown-item @click="goToHome">
            <feather-icon icon="ArrowLeftIcon" />
            <span class="align-middle ml-50">Volver a contratos</span>
          </b-dropdown-item>
          <b-dropdown-item @click="setContractToEdit(selectedContract)" v-if="selectedContract">
            <feather-icon icon="DollarSignIcon" />
            <span class="align-middle ml-50">Beneficios y condiciones</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <FiltersTransactions :listYear="years" @get-transactions="getTransactions" :title="titleCard" :subtitle="subTitleCard"/>

      <b-alert variant="danger" v-if="contractIsCancelled" show>
        <div class="alert-body text-center">
          <span><strong>Contrato Cancelado</strong></span>
        </div>
      </b-alert>

      <div class="text-center" v-if="isLoadingTransactions">
        <div class="alert-body text-center">
          <span>Cargando transacciones</span>
        </div>
        <b-spinner class="mb-2" label="Loading..." variant="success" />
      </div>

      <Transactions
        @set-is-loading-transactions="setIsLoadingTransactions"
        @set-is-loading-transactions-detail="setIsLoadingTransactionsDetail"
        :idContract="idContract"
        :isLoadingTransactions="isLoadingTransactions"
      />

      <div class="text-center" v-if="isLoadingTransactionsDetail">
        <b-alert show >
          <div class="alert-body text-center">
            <span>Cargando detalles de transacciones</span>
          </div>
          <b-spinner class="mb-2" label="Loading..." variant="success" />
        </b-alert>
      </div>
    </b-card>
  </div>
</template>

<script>
import {  mapState, mapActions, mapMutations } from "vuex";
import { VBTooltip } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { listYear } from '@/helpers/helpers'
import Transactions from "@/modules/fivesClub/components/contracts/Transactions"
import FiltersTransactions from "@/modules/fivesClub/components/contracts/FiltersTransactions"
export default {
  directives: { "b-tooltip": VBTooltip, Ripple, },
  components: {
    Transactions,
    FiltersTransactions
  },
  created() {
    this.orderUrl = this.$route.params.contracts;
    this.setOrderFromUrl(this.orderUrl);
    if( this.year === ''){
      const currentYear = new Date().getFullYear()
      this.year = currentYear
    }
  },
  async mounted(){
    await this.getTransactions(this.year)
    const contract = await this.fetchContracts({IdContract: this.idContract, simple: true})
    if (contract) this.setTitleCard(contract)
  },
  data() {
    return {
      authURL: process.env.VUE_APP_IMG_SRC_API,
      orderUrl: "",
      idContract: "",
      isLoadingTransactionsDetail: false,
      isLoadingTransactions:false,
      year: '',
      titleCard: '',
      subTitleCard:''
    };
  },
  computed:{
    ...mapState('fivesClubContracts', ['selectedContract','transactions']),
    years(){
      return listYear()
    },
    contractIsCancelled(){
      const canceledTrans = this.transactions?.some(trans => trans.statusContract == 3)
      return this.selectedContract?.statusContract == 3 || canceledTrans
    }
  },
  methods: {    
	...mapActions("fivesClubContracts", ["fetchTransactions", "fetchContracts"]),
    ...mapMutations('fivesClubContracts', ['setSelectedContract','setTransactions','setDetailTransactions']),

    async setOrderFromUrl(cadena) {
      const arreglo = cadena.split("-", 2);
      const idOrder = parseInt(arreglo[1]);
      const orderIsANumber = !isNaN(idOrder);
      if (orderIsANumber) this.idContract = idOrder
    },
    async getTransactions(year){
      this.setTransactions([])
      this.setIsLoadingTransactions(true)
      this.year = year
      const payload = { id: this.idContract, year: year }
      this.setTransactions(await this.fetchTransactions(payload))
      this.setDetailTransactions([])
      this.setIsLoadingTransactions(false)
    },
    setContractToEdit(contract) {
      if(contract){
        this.setSelectedContract(contract)
        this.$router.push({ name: 'edit-contract', params: { idContract: contract.id } })
      }
    },
    goToHome(){
      this.setSelectedContract(null)
      this.$router.push({ name: 'contracts' })
    },
    setIsLoadingTransactions(bool){
      this.isLoadingTransactions = bool
    },
    setIsLoadingTransactionsDetail(bool){
      this.isLoadingTransactionsDetail = bool
    },
    setTitleCard(contract){
      this.titleCard = `${contract?.code || ''} | ${contract?.membershipcode || ''}`
      this.subTitleCard = `${contract?.resortname || ''} | ${contract?.numberHousing || ''} | ${contract?.categoryroomname || ''}`
    }
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
.btn-right {
  display: flex;
  justify-content: flex-end;
  margin-block-end: 1rem;
}
.card-link-dark {
  color: white;
}

.card-link-light {
  color: black;
}
</style>
