<template>
  <b-row class="mb-1">
    <b-col md="12 mt-0">
      <div class="d-flex justify-content-between">
        <div class="mb-1 mt-0">
          <h4>{{ title }}</h4>
          <h6>{{ subtitle }}</h6>
        </div>
      </div>
    </b-col>
    <b-col md="3">
      <b-form-group label="Seleccione Año*">
        <b-form-select v-model="yearSelect">
          <option value="" disabled>Seleccione Año</option>
          <option v-for="yearInfo in listYear" :key="yearInfo.id" :value="yearInfo.id">{{yearInfo.id}}</option>
        </b-form-select>
      </b-form-group>
    </b-col>

    <b-col md="2 mt-1 pt-1">
      <b-button variant="primary" @click="setFilter()">Buscar</b-button>
    </b-col>
  </b-row>
</template>

<script>


import Ripple from 'vue-ripple-directive'

export default {
  directives: { Ripple },
  props:{
    listYear:{
      type: Array,
      required: true,
    },
    title:{
      type: String,
      required: true,
    },
    subtitle:{
      type: String,
      required: true,
    },
  },
  mounted(){
    if(this.yearSelect === ''){
      const currentYear = new Date().getFullYear()
      this.yearSelect = currentYear
    }
  },
  data() {
    return {
      yearSelect:"",
    }
  },
  methods:{
   setFilter(){
      this.$emit('get-transactions', this.yearSelect)
    }
  }
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>