<template>
  <b-modal
    size="sm"
    :id="'modal-cancel-detail-transaction' + detail.id"
    :title="detail.benefit"
    ok-only
    no-close-on-backdrop
    hide-footer
  >
    <b-card-text>      
      <validation-observer ref="canceldetail" tag="form" v-slot="{ invalid }">
        <b-row class="mb-2">
          <b-col md="12">
            <ValidationProvider rules="required" name="Notas">
              <b-form-group label="Nota de cancelacion" slot-scope="{ valid, errors }">
                <b-form-textarea
                  v-model="detail.notesInfo"
                  placeholder="Escribe la nota de cancelacion"
                  :maxlength="100"
                  rows="2"
                  :state="errors[0] ? false : valid ? true : null"
                >
                </b-form-textarea>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col md="12">
            <div class="text-right">
              <b-button
                :disabled="invalid"
                variant="primary"
                @click="cancelDetailTransaction(detail)"
              >
                <b-spinner small v-if="isSaving" />Confirmar
              </b-button>
            </div>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card-text>
  </b-modal>
</template>

<script>
import * as moment from "moment";
import { mapActions, mapMutations } from "vuex";

export default {
  async mounted() {
    this.invalid = false;
  },
  props: {
    detail: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      invalid: false,
      isSaving: false,
      note: "",
    };
  },
  computed: {},
  methods: {
    async cancelDetailTransaction(item) {
      this.$emit("cancel-detail-transaction", item);
        item.notesInfo=""
      this.$root.$emit("bv::hide::modal", "modal-cancel-detail-transaction" + this.detail.id);
    },
  },
};
</script>


